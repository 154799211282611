import { SAVE_TEAM } from '@lib/graphql/mutations/Organization'
import { SEARCH_TEAMS_FULL } from '@lib/graphql/queries/Organization'
import lensPath from 'ramda/src/lensPath'
import set from 'ramda/src/set'
import view from 'ramda/src/view'
import { WithTeams } from '../Data'
import makeEntityMultiselect from '../entityControl/makeEntityMultiselect'

export default makeEntityMultiselect(WithTeams, {
  allowCreate: false,
  createOptions: {
    operationName: 'saveTeam',
    mutation: SAVE_TEAM,
    updateQuery: SEARCH_TEAMS_FULL,
    updateResolver: (queryData, result) => {
      const lens = lensPath(['searchTeams', 'teams'])
      const collection = view(lens, queryData)
      return set(lens, [result.data?.saveTeam, ...collection])(queryData)
    },
  },
  serializeCreate: (data) => data,
})
