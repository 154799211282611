import { Language } from '@lib/graphql/__generated__/graphql'
import formLocalesBG from '@locales/bg/form-locales.json'
import formLocalesEN from '@locales/en/form-locales.json'
import formLocalesFR from '@locales/fr/form-locales.json'
import FormValidation from '@utils/formValidation/index'
import { locales } from '@utils/i18n'
import path from 'ramda/src/path'
import React, { useEffect, useMemo } from 'react'

export const formLocales = {
  en: formLocalesEN,
  bg: formLocalesBG,
  fr: formLocalesFR,
}

export interface ITranslationsProviderProps {
  lang: string
  children: any
}

let internalLang

export const TranslationContext = React.createContext(null)

export const translate = (
  key: string,
  keywords?: string[],
  options?: {
    translations?: Record<string, any>
    lang?: Language
  },
) => {
  let { translations, lang = internalLang } = options || {}

  if (!translations) translations = locales[lang]
  const value: string | undefined = path(key.split('.'))(translations)

  if (!value) return key

  if (Array.isArray(keywords)) {
    const mutableKeywords = [...keywords]
    const replacedValue = value.replaceAll(/{.*?}/g, () =>
      mutableKeywords.shift(),
    )

    return replacedValue
  }

  return value
}

const TranslationsProvider = ({
  lang,
  children,
}: ITranslationsProviderProps) => {
  const translations = locales[lang]

  internalLang = lang

  const contextValue = useMemo(
    () => ({ lang, translations }),
    [lang, translations],
  )

  useEffect(() => {
    // feed FormValidation with the latest form translations
    FormValidation.config({
      minReplaceRegexp: /\{min}/g,
      maxReplaceRegexp: /\{max}/g,
      locale: formLocales[lang],
    })
  }, [lang])

  return (
    <TranslationContext.Provider value={contextValue}>
      {children}
    </TranslationContext.Provider>
  )
}

export default TranslationsProvider
