import { OnDataOptions, OperationVariables } from '@apollo/client'
import { UpdateQueryFn } from '@apollo/client/core/watchQueryOptions'
import { ApolloParams } from '@components/Admin/Admin/AdminPage'
import {
  EventType,
  SizeMatrix,
  ThreadCommentActivity,
  ThreadLikeActivity,
  ThreadLikeEvent,
  ThreadLikesSubscription,
} from '@lib/graphql/__generated__/graphql'
import { ACCOUNT_FRAGMENT } from '@lib/graphql/fragments/Organization'
import { GET_COUNTERS } from '@lib/graphql/queries/Shared'
import { SEARCH_RECENT_ACTIVITY_THREADS } from '@lib/graphql/queries/Threads'
import { Board } from '@lib/types/boards'
import { FashionCollection } from '@lib/types/collections'
import { Comment } from '@lib/types/comment'
import { UUID } from '@lib/types/general'
import { Goal } from '@lib/types/goals'
import { Project } from '@lib/types/project'
import { Task } from '@lib/types/task'
import { User } from '@lib/types/user'
import { refreshRemoteDataGridCache } from '@utils/EventEmitter/actions'
import { signOutClient } from '@utils/auth'
import { debounce } from '@utils/common'
import { GRID_ENTITY } from '@utils/constants'
import { getDynamicPlanningRowId } from '@utils/dynamicReminders'
import lensPath from 'ramda/src/lensPath'
import path from 'ramda/src/path'
import set from 'ramda/src/set'
import view from 'ramda/src/view'

const DEBOUNCE_TIME_MS = 800

const refreshGridOnSubscriptionData: ApolloParams['onSubscriptionData'] = ({
  result,
  gridApi,
}) => {
  if (!gridApi) return

  if (typeof result.data == 'object') {
    const type = (Object.values(result.data)[0] as Record<string, any>)
      ?.type as EventType

    const refreshGrid = () => gridApi.refreshServerSide()

    const eventTypeUpdaters = {
      [EventType.Updated]: refreshGrid,
      [EventType.Created]: refreshGrid,
      [EventType.Deleted]: refreshGrid,
    }

    const eventTypeUpdater = eventTypeUpdaters[type]

    if (eventTypeUpdater) {
      eventTypeUpdater()
    }
  }
}

export const dynamicRemindersSubscriptionDataHandler: UpdateQueryFn<
  any,
  OperationVariables,
  any
> = (prev, { subscriptionData }) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data.dynamicReminders?.type as EventType
  const dynamicPlanningData = data.dynamicReminders?.dynamicPlanning
  const cachedData = prev.dynamicPlannings ?? []

  if (!dynamicPlanningData) return prev

  const eventTypeUpdaters = {
    [EventType.Created]: (cachedData, dynamicPlanningData) => {
      const existingData = cachedData.find(
        (planning) =>
          getDynamicPlanningRowId(planning) ===
          getDynamicPlanningRowId(dynamicPlanningData),
      )

      if (existingData) return cachedData

      return [...cachedData, dynamicPlanningData]
    },
    [EventType.Updated]: (cachedData, dynamicPlanningData) => {
      return cachedData.map((planning) =>
        getDynamicPlanningRowId(planning) ===
        getDynamicPlanningRowId(dynamicPlanningData)
          ? dynamicPlanningData
          : planning,
      )
    },
    [EventType.Deleted]: (cachedData, dynamicPlanningData) => {
      return cachedData.filter(
        (planning) =>
          getDynamicPlanningRowId(planning) !==
          getDynamicPlanningRowId(dynamicPlanningData),
      )
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  return {
    ...prev,
    dynamicPlannings: eventTypeUpdater(cachedData, dynamicPlanningData),
  }
}

const tasksSubscriptionDataHandler = (
  lens: (data) => any,
  prev: Record<string, any>,
  { subscriptionData },
  newPosition: 'top' | 'bottom',
) => {
  const data = Object.values(subscriptionData?.data ?? {})?.[0] as {
    deletedStandbyTaskId?: UUID
    type: EventType
    task: Task
  }

  if (!data) return prev

  const type = data.type
  const taskData = data.task
  const deletedStandbyTaskId = data.deletedStandbyTaskId
  const cachedData = view(lens, prev) ?? []

  if (!taskData) return prev

  const eventTypeUpdaters = {
    [EventType.Created]: (
      cachedData: Task[],
      taskData: Task,
      deletedStandbyTaskId: UUID,
    ) => {
      let updatedData = cachedData

      if (deletedStandbyTaskId) {
        const taskIndex = cachedData.findIndex(
          (task) => task.id === deletedStandbyTaskId,
        )

        if (taskIndex > -1) {
          const dataCopy = [...cachedData]

          dataCopy.splice(taskIndex, 1, taskData)

          return dataCopy
        }
      }

      const existingData = updatedData.find((task) => task.id === taskData.id)

      if (existingData) return updatedData

      updatedData =
        newPosition === 'top'
          ? [taskData, ...updatedData]
          : [...updatedData, taskData]

      return updatedData
    },
    [EventType.Updated]: (cachedData: Task[], taskData: Task) => {
      const newData = cachedData.map((task) =>
        task.id === taskData.id ? taskData : task,
      )

      return newData
    },
    [EventType.Deleted]: (cachedData: Task[], taskData: Task) => {
      const newData = cachedData.filter((task) => task.id !== taskData.id)

      return newData
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  const newData = eventTypeUpdater(cachedData, taskData, deletedStandbyTaskId)

  return set(lens, newData, prev)
}

export const relatedTasksSubscriptionDataHandler: UpdateQueryFn<
  any,
  OperationVariables,
  any
> = (prev, { subscriptionData }) => {
  const taskTreeData: Task = subscriptionData?.data?.taskTree?.taskTree

  if (!Array.isArray(taskTreeData)) return prev

  return {
    ...prev,
    getRelatedTasks: taskTreeData,
  }
}

export const projectDetailsSubscriptionDataHandler: UpdateQueryFn<
  any,
  OperationVariables,
  any
> = (prev, { subscriptionData }) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data.project?.type as EventType
  const projectData: Project = data.project?.project
  const cachedData = prev.getProject ?? {}

  if (!projectData) return prev

  const eventTypeUpdaters = {
    [EventType.Updated]: (cachedData: Project) => {
      return {
        ...cachedData,
        ...projectData,
      }
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  return {
    ...prev,
    getProject: eventTypeUpdater(cachedData),
  }
}

export const goalDetailsSubscriptionDataHandler: UpdateQueryFn<
  any,
  OperationVariables,
  any
> = (prev, { subscriptionData }) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data.goal?.type as EventType
  const goalData: Goal = data.goal?.goal
  const cachedData = prev.getGoal ?? {}

  if (!goalData) return prev

  const eventTypeUpdaters = {
    [EventType.Updated]: (cachedData: Goal) => {
      return {
        ...cachedData,
        ...goalData,
      }
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  return {
    ...prev,
    getGoal: eventTypeUpdater(cachedData),
  }
}

export const recurringDefinitionSubscriptionDataHandler: UpdateQueryFn<
  any,
  OperationVariables,
  any
> = (prev, { subscriptionData }) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data.project?.type as EventType
  const recurringDefinitionData = data.recurringDefinition?.definition
  const cachedData = prev.getRecurringDefinition ?? {}

  if (!recurringDefinitionData) return prev

  const eventTypeUpdaters = {
    [EventType.Updated]: (cachedData) => {
      return {
        ...cachedData,
        ...recurringDefinitionData,
      }
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  return {
    ...prev,
    getRecurringDefinition: eventTypeUpdater(cachedData),
  }
}

const myGoalsSubscriptionDataHandler = (
  subscriptionName: string,
  lens,
  prev,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data[subscriptionName]?.type as EventType
  const goalData: Goal = data[subscriptionName]?.goal

  const cachedData = view(lens, prev) ?? []

  if (!goalData) return prev

  const eventTypeUpdaters = {
    [EventType.Created]: (cachedData: Goal[]) => {
      if (cachedData.find((g) => g.id === goalData.id)) return cachedData

      return [...cachedData, goalData]
    },
    [EventType.Updated]: (cachedData: Goal[]) => {
      return cachedData.map((g) =>
        g.id === goalData.id ? { ...g, ...goalData } : g,
      )
    },
    [EventType.Deleted]: (cachedData: Goal[]) => {
      return cachedData.filter((g) => g.id !== goalData.id)
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  const updatedData = eventTypeUpdater(cachedData)

  return set(lens, updatedData, prev)
}

export const boardsSubscriptionDataHandler: UpdateQueryFn<
  any,
  OperationVariables,
  any
> = (prev, { subscriptionData }) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data.boards?.type as EventType
  const boardData: Board = data.boards?.board
  const cachedData = prev.searchBoards?.boards ?? []

  if (!boardData) return prev

  const eventTypeUpdaters = {
    [EventType.Created]: (cachedData: Board[], boardData: Board) => {
      const existingData = cachedData.find((board) => board.id === boardData.id)

      if (existingData) return cachedData

      return [boardData, ...cachedData]
    },
    [EventType.Updated]: (cachedData: Board[], boardData: Board) => {
      return cachedData.map((board) =>
        board.id === boardData.id ? boardData : board,
      )
    },
    [EventType.Deleted]: (cachedData: Board[], boardData: Board) => {
      return cachedData.filter((board) => board.id !== boardData.id)
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  return {
    ...prev,
    searchBoards: {
      ...prev.searchBoards,
      boards: eventTypeUpdater(cachedData, boardData),
    },
  }
}

export const accountsSubscriptionDataHandler = (
  currentUserAccount: User,
  { client, data: subscriptionResult }: OnDataOptions<any>,
) => {
  const { data } = subscriptionResult

  const type = data.accounts?.type as EventType
  const account = data.accounts?.account as User

  if (!type || !account) return

  const cache = client.cache

  const eventTypeUpdaters = {
    [EventType.Created]: () => {
      // if the user is currently on administration/users screen - refresh the grid
      refreshRemoteDataGridCache(GRID_ENTITY.ADMIN_USERS)
    },
    [EventType.Updated]: () => {
      if (account.id === currentUserAccount?.id && account.isActive === false) {
        // current logged in user has been deactivated
        // log him out
        signOutClient()
        return
      }
      refreshRemoteDataGridCache(GRID_ENTITY.ADMIN_USERS)

      cache.writeFragment({
        id: account.id,
        data: account,
        fragment: ACCOUNT_FRAGMENT,
        fragmentName: 'AccountFragment',
      })
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (eventTypeUpdater) eventTypeUpdater()
}

export const newNotificationSubscriptionDataHandler = ({
  client,
}: OnDataOptions<any>) => {
  const cache = client.cache

  cache.updateQuery(
    {
      query: GET_COUNTERS,
    },
    (queryData) => {
      if (!queryData) return queryData

      const lens = lensPath(['getCounters', 'notifications'])
      const notifications = view(lens, queryData)

      const updatedNotifications = notifications + 1

      return set(lens, updatedNotifications, queryData)
    },
  )
}

const commentsSubscriptionDataHandler = (
  lens: (data) => any,
  prev: Record<string, any>,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data.comments?.type as EventType
  const commentData: Comment = data.comments?.comment
  const cachedData = view(lens, prev) ?? []

  if (!commentData) return prev

  const eventTypeUpdaters = {
    [EventType.Created]: (cachedData: Comment[], commentData: Comment) => {
      const existingData = cachedData.find(
        (comment) => comment.id === commentData.id,
      )

      if (existingData) return cachedData

      return [commentData, ...cachedData]
    },
    [EventType.Updated]: (cachedData: Comment[], commentData: Comment) => {
      return cachedData.map((comment) =>
        comment.id === commentData.id ? commentData : comment,
      )
    },
    [EventType.Deleted]: (cachedData: Comment[], commentData: Comment) => {
      return cachedData.filter((comment) => comment.id !== commentData.id)
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  const newData = eventTypeUpdater(cachedData, commentData)

  return set(lens, newData, prev)
}

export const projectSizeMatrixSubscriptionDataHandler = (
  prev: Record<string, any>,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const type = data.projectSizeMatrix?.type as EventType
  const sizeMatrixData: SizeMatrix = data.projectSizeMatrix?.sizeMatrix
  const cachedData = prev.searchSizeMatrices?.sizeMatrices

  if (!sizeMatrixData) return prev

  const eventTypeUpdaters = {
    [EventType.Created]: (
      cachedData: SizeMatrix[],
      sizeMatrixData: SizeMatrix,
    ) => {
      const existingData = cachedData.find(
        (sizeMatrix) => sizeMatrix.id === sizeMatrixData.id,
      )

      if (existingData) return cachedData

      return [sizeMatrixData, ...cachedData]
    },
    [EventType.Updated]: (
      cachedData: SizeMatrix[],
      sizeMatrixData: SizeMatrix,
    ) => {
      return cachedData.map((sizeMatrix) =>
        sizeMatrix.id === sizeMatrixData.id ? sizeMatrixData : sizeMatrix,
      )
    },
    [EventType.Deleted]: (
      cachedData: SizeMatrix[],
      sizeMatrixData: SizeMatrix,
    ) => {
      return cachedData.filter(
        (sizeMatrix) => sizeMatrix.id !== sizeMatrixData.id,
      )
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  const newData = eventTypeUpdater(cachedData, sizeMatrixData)

  const lens = lensPath(['searchSizeMatrices', 'sizeMatrices'])

  return set(lens, newData, prev)
}

const taskCountersSubscriptionDataHandler = (
  prop: string,
  prev,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  if (typeof data?.counter?.value !== 'number') {
    return prev
  }

  return {
    ...prev,
    getTaskDetailsCounters: {
      ...prev.getTaskDetailsCounters,
      [prop]: data.counter.value,
    },
  }
}

const projectCountersSubscriptionDataHandler = (
  prop: string,
  prev,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  if (typeof data?.counter?.value !== 'number') {
    return prev
  }

  return {
    ...prev,
    getProjectDetailsCounters: {
      ...prev.getProjectDetailsCounters,
      [prop]: data.counter.value,
    },
  }
}

const navigationCountersSubscriptionDataHandler = (
  prop: string,
  prev,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  if (typeof data?.counter?.value !== 'number') {
    return prev
  }

  return {
    ...prev,
    getCounters: {
      ...prev.getCounters,
      [prop]: data.counter.value,
    },
  }
}

const threadCountersSubscriptionDataHandler = (
  prop: string,
  prev,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  if (typeof data?.counter?.value !== 'number') {
    return prev
  }

  return {
    ...prev,
    threadCounters: {
      ...prev.threadCounters,
      [prop]: data.counter.value,
    },
  }
}

export const userTaskCounterSubscriptionDataHandler = (
  prev,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  return {
    ...prev,
    getCounters: {
      ...prev.getCounters,
      tasks: {
        ...(prev.getCounters?.tasks ?? {}),
        ...data?.userTaskCounter,
      },
    },
  }
}

export const userToDoCounterSubscriptionDataHandler = (
  prev,
  { subscriptionData },
) => {
  const data = subscriptionData?.data

  return {
    ...prev,
    getCounters: {
      ...prev.getCounters,
      tasks: {
        ...(prev.getCounters?.todos ?? {}),
        ...data?.userTodoCounter,
      },
    },
  }
}

export const remindersCountSubscriptionDataHandler = (prev, data) =>
  navigationCountersSubscriptionDataHandler('dynamicPlannings', prev, data)

export const todosCountSubscriptionDataHandler = (prev, data) =>
  navigationCountersSubscriptionDataHandler('todos', prev, data)

export const unreadThreadsCountSubscriptionDataHandler = (prev, data) =>
  threadCountersSubscriptionDataHandler('unread', prev, data)

export const taskCommentsCountSubscriptionDataHandler = (prev, data) =>
  taskCountersSubscriptionDataHandler('commentsCount', prev, data)

export const taskResourcesCountSubscriptionDataHandler = (prev, data) =>
  taskCountersSubscriptionDataHandler('resourcesCount', prev, data)

export const projectMilestonesCountSubscriptionDataHandler = (prev, data) =>
  projectCountersSubscriptionDataHandler('milestonesCount', prev, data)

export const projectResourcesCountSubscriptionDataHandler = (prev, data) =>
  projectCountersSubscriptionDataHandler('resourcesCount', prev, data)

export const projectSizeMatricesCountSubscriptionDataHandler = (prev, data) =>
  projectCountersSubscriptionDataHandler('sizeMatricesCount', prev, data)

export const projectMaterialsCountSubscriptionDataHandler = (prev, data) =>
  projectCountersSubscriptionDataHandler('projectMaterialsCount', prev, data)

export const goalCommentsSubscriptionDataHandler = (prev, data) =>
  commentsSubscriptionDataHandler(lensPath(['getGoal', 'comments']), prev, data)

export const taskCommentsSubscriptionDataHandler = (prev, data) =>
  commentsSubscriptionDataHandler(lensPath(['getTask', 'comments']), prev, data)

export const threadCommentsSubscriptionDataHandler = (prev, data) =>
  commentsSubscriptionDataHandler(
    lensPath(['getThread', 'comments']),
    prev,
    data,
  )

export const ownedGoalsSubscriptionDataHandler = (prev, data) =>
  myGoalsSubscriptionDataHandler(
    'ownedGoals',
    lensPath('getOwnedGoals'),
    prev,
    data,
  )

export const assignedGoalsSubscriptionDataHandler = (prev, data) =>
  myGoalsSubscriptionDataHandler(
    'assignedGoals',
    lensPath(['getAssignedGoals']),
    prev,
    data,
  )

export const followedGoalsSubscriptionDataHandler = (prev, data) =>
  myGoalsSubscriptionDataHandler(
    'followedGoals',
    lensPath(['getFollowedGoals']),
    prev,
    data,
  )
export const milestoneTasksSubscriptionDataHandler = (prev, data) =>
  tasksSubscriptionDataHandler(
    lensPath(['getMilestone', 'tasks']),
    prev,
    data,
    'bottom',
  )

export const goalTasksSubscriptionDataHandler = (prev, data) =>
  tasksSubscriptionDataHandler(
    lensPath(['getGoal', 'tasks']),
    prev,
    data,
    'top',
  )

export const projectsSubscriptionDataHandler: ApolloParams['onSubscriptionData'] =
  debounce(DEBOUNCE_TIME_MS, refreshGridOnSubscriptionData)

export const projectMilestonesSubscriptionDataHandler: ApolloParams['onSubscriptionData'] =
  debounce(DEBOUNCE_TIME_MS, refreshGridOnSubscriptionData)

export const myTasksSubscriptionDataHandler: ApolloParams['onSubscriptionData'] =
  debounce(DEBOUNCE_TIME_MS, refreshGridOnSubscriptionData)

export const resourcesSubscriptionDataHandler: ApolloParams['onSubscriptionData'] =
  debounce(DEBOUNCE_TIME_MS, refreshGridOnSubscriptionData)

export const recurringTaskListSubscriptionDataHandler: ApolloParams['onSubscriptionData'] =
  debounce(DEBOUNCE_TIME_MS, refreshGridOnSubscriptionData)

export const sizeMatricesListSubscriptionDataHandler: ApolloParams['onSubscriptionData'] =
  debounce(DEBOUNCE_TIME_MS, ({ gridApi }) => {
    gridApi?.refreshServerSide()
  })

export const notificationListSubscriptionDataHandler: ApolloParams['onSubscriptionData'] =
  debounce(DEBOUNCE_TIME_MS, ({ gridApi }) => {
    gridApi?.refreshServerSide()
  })

export const collectionsSubscriptionDataHandler: UpdateQueryFn<
  any,
  OperationVariables,
  any
> = (prev, { subscriptionData }) => {
  const data = subscriptionData?.data

  if (!data) return prev

  const fashionCollectionSubsriptionData = data.fashionCollections
  const type = fashionCollectionSubsriptionData?.type as EventType
  const collectionData: FashionCollection =
    fashionCollectionSubsriptionData.collection
  const cachedData = prev.searchFashionCollections?.collections ?? []

  if (!collectionData) return prev

  const eventTypeUpdaters = {
    [EventType.Created]: (
      cachedData: FashionCollection[],
      collection: FashionCollection,
    ) => {
      const existingData = cachedData.find((c) => c.id === collection.id)

      if (existingData) return cachedData

      return [collectionData, ...cachedData]
    },
    [EventType.Updated]: (
      cachedData: FashionCollection[],
      collectionData: FashionCollection,
    ) => {
      return cachedData.map((c) =>
        c.id === collectionData.id ? collectionData : c,
      )
    },
    [EventType.Deleted]: (
      cachedData: FashionCollection[],
      collectionData: FashionCollection,
    ) => {
      return cachedData.filter((c) => c.id !== collectionData.id)
    },
  }

  const eventTypeUpdater = eventTypeUpdaters[type]

  if (!eventTypeUpdater) return prev

  return {
    ...prev,
    searchFashionCollections: {
      ...prev.searchFashionCollections,
      collections: eventTypeUpdater(cachedData, collectionData),
    },
  }
}

export const threadRecentCommentActivityDataHandler = async ({
  data,
  client,
  getThread,
  queryVariables,
}) => {
  const threadComment = path(['data', 'threadComments'])(data)

  const response = await getThread({
    fetchPolicy: 'network-only',
    variables: { threadId: threadComment.thread.id },
  })

  const thread = response?.data?.getThread

  if (!thread) return

  client.cache.updateQuery(
    {
      query: SEARCH_RECENT_ACTIVITY_THREADS,
      variables: queryVariables,
    },
    (queryData) => {
      const activities = queryData?.recentThreads?.activities ?? []

      const existingActivity = activities.find(
        (activity) => activity?.['thread']?.id === thread.id,
      )
      if (!existingActivity && thread?.unreadComments) {
        return {
          ...queryData,
          recentThreads: {
            ...queryData.recentThreads,
            activities: [
              {
                __typename:
                  'ThreadCommentActivity' as ThreadCommentActivity['__typename'],
                lastParticipant: threadComment?.author,
                recentParticipants: [threadComment?.author],
                thread,
              },
              ...activities,
            ],
          },
        }
      }

      if (existingActivity && thread?.unreadComments) {
        return {
          ...queryData,
          recentThreads: {
            ...queryData.recentThreads,
            activities: activities
              .map((activity) => {
                if (activity?.['thread']?.id === thread.id) {
                  return {
                    ...activity,
                    lastParticipant: threadComment?.author,
                    recentParticipants: [
                      ...(activity?.['recentParticipants'] ?? []),
                      threadComment?.author,
                    ],
                    thread,
                  }
                }

                return activity
              })
              .sort((a, b) => {
                if (a?.['thread']?.id === thread.id) return -1
                if (b?.['thread']?.id === thread.id) return 1

                return 0
              }),
          },
        }
      }

      return queryData
    },
  )
}

export const threadRecentLikeActivityDataHandler = ({
  data,
  client,
  queryVariables,
}: {
  data: OnDataOptions<ThreadLikesSubscription>['data']
  client: OnDataOptions<ThreadLikesSubscription>['client']
  queryVariables: Record<string, any>
}) => {
  const threadLike = path(['data', 'threadLikes'])(data) as ThreadLikeEvent

  if (!threadLike) return

  const isUnLike = !threadLike.time

  client.cache.updateQuery(
    {
      query: SEARCH_RECENT_ACTIVITY_THREADS,
      variables: queryVariables,
    },
    (queryData) => {
      const activities = queryData?.recentThreads?.activities ?? []

      if (isUnLike) {
        return {
          ...queryData,
          recentThreads: {
            ...queryData.recentThreads,
            activities: activities.filter(
              (activity) => activity?.['comment']?.id === threadLike.comment.id,
            ),
          },
        }
      }

      const existingActivity = activities.find(
        (activity) => activity?.['comment']?.id === threadLike.comment.id,
      )

      if (existingActivity) {
        return {
          ...queryData,
          recentThreads: {
            ...queryData.recentThreads,
            activities: activities.map((activity) => {
              if (activity?.['comment']?.id === threadLike.comment.id) {
                return {
                  ...activity,
                  accounts: [
                    ...(activity as ThreadLikeActivity).accounts,
                    threadLike.author,
                  ],
                  comment: {
                    ...threadLike.comment,
                  },
                  time: threadLike?.time,
                }
              }

              return activity
            }),
          },
        }
      }

      return {
        ...queryData,
        recentThreads: {
          ...queryData.recentThreads,
          activities: [
            {
              __typename:
                'ThreadLikeActivity' as ThreadLikeActivity['__typename'],
              accounts: [threadLike.author],
              comment: threadLike.comment,
              time: threadLike.time,
            },
            ...activities,
          ],
        },
      }
    },
  )
}
