import { OnDataOptions, useQuery, useSubscription } from '@apollo/client'
import {
  ALL_ACCOUNTS,
  SEARCH_TEAMS_FULL,
} from '@lib/graphql/queries/Organization'
import {
  ACCOUNTS_SUBSCRIPTION,
  NOTIFICATIONS_SUBSCRIPTION,
  THREAD_NOTIFICATION_SUBSCRIPTION,
} from '@lib/graphql/subscriptions/Shared'
import {
  accountsSubscriptionDataHandler,
  newNotificationSubscriptionDataHandler,
} from '@lib/grid/subscriptions/subscriptionsEventHandlers'
import useTranslations from '@locales/useTranslations'
import { renderNotification } from '@utils/notifications/notifications'
import { useEffect, useRef } from 'react'
import { renderToStaticMarkup } from 'react-dom/server'
import useUser from './useUser'

const useSharedData = () => {
  const { t } = useTranslations()
  const { account } = useUser()
  const threadNotificationSound = useRef(null)

  useQuery(ALL_ACCOUNTS, {
    fetchPolicy: 'network-only',
    pollInterval: 1000 * 60 * 60 * 24,
  })

  useQuery(SEARCH_TEAMS_FULL, {
    pollInterval: 1000 * 60 * 60 * 24,
  })

  const handleBrowserNotification = (options: OnDataOptions<any>) => {
    const notification = options.data.data?.notifications

    if (notification) {
      const notificationBody = renderNotification({
        data: notification,
        t,
      })

      if (notificationBody !== null) {
        const html = renderToStaticMarkup(notificationBody)
        let doc = new DOMParser().parseFromString(html, 'text/html')

        if (doc?.body?.textContent) {
          const body = doc?.body?.textContent
          const favicon =
            process.env.NEXT_PUBLIC_CLIENT_TYPE == 'fashion'
              ? '/favicon-fashion.ico'
              : '/favicon.ico'

          new Notification('Havelock', {
            body,
            icon: favicon,
            tag: notification?.id,
          })
        }
      }
    }
  }

  const handleNotificationSound = () => {
    if (!account?.id || !account?.playThreadNotificationSound) return

    threadNotificationSound.current?.play()
  }

  useSubscription(ACCOUNTS_SUBSCRIPTION, {
    onData: (...params) => accountsSubscriptionDataHandler(account, ...params),
  })

  useSubscription(NOTIFICATIONS_SUBSCRIPTION, {
    onData: (options) => {
      handleBrowserNotification(options)

      return newNotificationSubscriptionDataHandler(options)
    },
  })

  useSubscription(THREAD_NOTIFICATION_SUBSCRIPTION, {
    onData: handleNotificationSound,
  })

  useEffect(() => {
    threadNotificationSound.current = new Audio(
      '/thread-notification-sound.mp3',
    )
  }, [])
}

export default useSharedData
