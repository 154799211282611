import DefaultTab from '@components/Shared/AccessComponent/AccessTabs/DefaultTab'
import PrivateTab from '@components/Shared/AccessComponent/AccessTabs/PrivateTab'
import ProjectPublicTab from '@components/Shared/AccessComponent/AccessTabs/ProjectPublicTab'
import SharedTab from '@components/Shared/AccessComponent/AccessTabs/SharedTab'
import {
  ChangeType,
  ContextType,
  DeadlineRequestStatus,
  OrderStatus,
  UserAccess,
} from '@lib/graphql/__generated__/graphql'
import { TFunction } from '@locales/useTranslations'
import { format } from 'date-fns'
import isEmpty from 'ramda/src/isEmpty'
import isNil from 'ramda/src/isNil'
import path from 'ramda/src/path'
import toLower from 'ramda/src/toLower'

export const ATTACHMENTS_FILTERS = {
  DEFAULT: 'default',
  SHOW_ALL_PROJECT_ATTACHMENTS: 'All project files',
}

export const TASK_TYPES = {
  MAIN: 'ROOT',
  DUPLICATE: 'DUPLICATE',
  SUBTASK: 'SUBTASK',
}

export const PROJECT_VISIBILITY = (t?: TFunction) => ({
  PRIVATE: {
    key: 'PRIVATE',
    value: t?.('expressions.private'),
    description: t?.('expressions.privateDescription'),
  },
  SHARED: {
    key: 'SHARED',
    value: t?.('expressions.shared'),
    description: t?.('expressions.sharedDescription'),
  },
  PUBLIC: {
    key: 'PUBLIC',
    value: t?.('expressions.public'),
    description: t?.('expressions.publicDescription'),
  },
})

export const RESOURCE_ACCESS = (t?: TFunction, isToDo?: boolean) => ({
  DEFAULT: {
    key: 'DEFAULT',
    value: t?.('buttons.default'),
    description: t?.(
      `expressions.${isToDo ? 'toDoDefaultDescription' : 'defaultDescription'}`,
    ),
  },
  CUSTOM: {
    key: 'CUSTOM',
    value: t?.('expressions.custom'),
    description: t?.(
      `expressions.${isToDo ? 'toDoCustomDescription' : 'customDescription'}`,
    ),
  },
})

export const CUSTOM_FIELD_DEFINITION_ACCESS = (t?: TFunction) => ({
  DEFAULT: {
    key: 'DEFAULT',
    value: t?.('expressions.public'),
    description: t?.('expressions.publicFieldDescription'),
  },
  CUSTOM: {
    key: 'CUSTOM',
    value: t?.('expressions.restricted'),
    description: t?.('expressions.sharedFieldDescription'),
  },
})

export const PROJECT_ACCESS_TABS = (t?: TFunction) => [
  {
    ...PROJECT_VISIBILITY(t).PRIVATE,
    BodyComponent: PrivateTab,
  },
  {
    ...PROJECT_VISIBILITY(t).SHARED,
    BodyComponent: SharedTab,
  },
  {
    ...PROJECT_VISIBILITY(t).PUBLIC,
    BodyComponent: ProjectPublicTab,
  },
]

export const RESOURCE_ACCESS_TABS = (t: TFunction, isToDo: boolean) => [
  {
    ...RESOURCE_ACCESS(t, isToDo).DEFAULT,
    BodyComponent: DefaultTab,
  },
  {
    ...RESOURCE_ACCESS(t, isToDo).CUSTOM,
    BodyComponent: SharedTab,
  },
]

export const CUSTOM_FIELD_DEFINITIONS_ACCESS_TABS = (t: TFunction) => [
  {
    ...CUSTOM_FIELD_DEFINITION_ACCESS(t).DEFAULT,
    BodyComponent: () => null,
  },
  {
    ...CUSTOM_FIELD_DEFINITION_ACCESS(t).CUSTOM,
    BodyComponent: SharedTab,
  },
]

export const CONTRIBUTOR_TYPE = {
  USER: 'USER',
  TEAM: 'TEAM',
}

export const ACCESS_CONTRIBUTOR_TYPES = {
  [PROJECT_VISIBILITY().PRIVATE.key]: {
    [CONTRIBUTOR_TYPE.USER]: true,
    [CONTRIBUTOR_TYPE.TEAM]: false,
  },
  [PROJECT_VISIBILITY().SHARED.key]: {
    [CONTRIBUTOR_TYPE.USER]: true,
    [CONTRIBUTOR_TYPE.TEAM]: true,
  },
  [PROJECT_VISIBILITY().PUBLIC.key]: {
    [CONTRIBUTOR_TYPE.USER]: true,
    [CONTRIBUTOR_TYPE.TEAM]: false,
  },
  [RESOURCE_ACCESS().DEFAULT.key]: {
    [CONTRIBUTOR_TYPE.USER]: false,
    [CONTRIBUTOR_TYPE.TEAM]: false,
  },
  [RESOURCE_ACCESS().CUSTOM.key]: {
    [CONTRIBUTOR_TYPE.USER]: true,
    [CONTRIBUTOR_TYPE.TEAM]: true,
  },
}

export const ATTACHABLE_TYPES = {
  PROJECT: 'Project',
  MILESTONE: 'ProjectMilestone',
  TASK: 'Task',
  COMMENT: 'Comment',
}

export const MILESTONE_TYPES = {
  REGULAR: 'REGULAR',
  SERVICE: 'SERVICE',
}

export const RECURRING_PERIOD = {
  DAY: 'DAYS',
  MONTH: 'MONTHS',
  WEEK: 'WEEKS',
  YEAR: 'YEARS',
}

export const RECURRING_END = {
  ON_DATE: 'ON_DATE',
  AFTER_OCCURRENCES: 'AFTER_OCCURRENCES',
  NEVER: 'NEVER',
}

export const RECURRING_END_DATA = (t: TFunction) => [
  { key: RECURRING_END.ON_DATE, label: t('expressions.on') },
  {
    key: RECURRING_END.AFTER_OCCURRENCES,
    label: t('expressions.after'),
  },
  { key: RECURRING_END.NEVER, label: t('expressions.never') },
]

export const QUERY_ACTIONS = {
  CREATE: 'create',
  BACK: 'back',
  ADD_EXCEPTION: 'add-exception',
  CREATE_PROJECT: 'create-project',
  CUSTOMIZE_PROJECT_GRID: 'customize-project-grid',
  CREATE_FIRST_PROJECT: 'create-first-project',
  CREATE_MILESTONE: 'create-milestone',
  EDIT_MILESTONE: 'edit-milestone',
  VIEW_SIZE_MATRIX: 'view-size-matrix',
  VIEW_DATES_HISTORY: 'view-dates-history',
  CREATE_SIZE_MATRIX: 'create-size-matrix',
  EDIT_SIZE_MATRIX: 'edit-size-matrix',
  CREATE_TASK: 'create-task',
  EDIT_TASK: 'edit-task',
  ACTIVATE_TASK: 'activate-task',
  CREATE_SUB_TASK: 'create-sub-task',
  CREATE_RECURRING_TASK: 'create-recurring-task',
  UPDATE_RECURRENCE: 'update-recurrence',
  EDIT_MILESTONE_DEPENDENCY: 'edit-milestone-dependency',
  LOAD_PROCEDURE: 'load-procedure',
  SAVE_PROCEDURE: 'save-procedure',
  VIEW_MILESTONE_ATTACHMENTS: 'view-milestone-attachments',
  VIEW_DETAILS: 'view-details',
  VIEW_DESCRIPTION: 'view-description',
  VIEW_COMMENT_LIBRARY: 'view-comment-library',
  EDIT_NOTES: 'edit-notes',
  EDIT_TITLE: 'edit-title',
  EDIT_USER: 'edit-user',
  EDIT_PROFILE_SETTINGS: 'edit-profile-settings',
  EDIT_COLLECTION_ACCESS: 'edit-collection-access',
  UPLOAD_FILE: 'upload-file',
  UPLOAD_FIRST_FILE: 'upload-first-file',
  ADD_LINK: 'add-link',
  ADD_SUB_GOAL: 'add-sub-goal',
  ADD_FIRST_LINK: 'add-first-link',
  EDIT_LOGGED_TIME: 'edit-logged-time',
  EDIT_ASSIGNEES: 'edit-assignees',
  EDIT_PROJECT_VISIBILITY: 'edit-project-visibility',
  EDIT_PROJECT_DETAILS: 'edit-project-details',
  EDIT_RESOURCE_ACCESS: 'edit-resource-access',
  LINK_COLLECTION_ITEM_PROJECTS: 'link-collection-item-projects',
  GENERATE_TIME_TRACKING_REPORT: 'generate-time-tracking-report',
  GENERATE_TIMELINE_REPORT: 'generate-timeline-report',
  EDIT_TIME_TRACKING_REPORT: 'edit-time-tracking-report',
  EDIT_TIMELINE_REPORT: 'edit-timeline-report',
  CREATE_GOAL: 'create-goal',
  CREATE_BOARD: 'create-board',
  CREATE_COLLECTION: 'create-collection',
  CREATE_COLLECTION_ITEM: 'create-collection-item',
  CREATE_MATERIAL: 'create-material',
  COPY_MATERIALS: 'copy-materials',
  EDIT_BOARD_ACCESS: 'edit-board-access',
  LINK_GOAL_PROJECTS: 'link-goal-projects',
  VIEW_GOAL_DETAILS_MODAL: 'view-goal-details-modal',
  CREATE_PROCEDURE_MILESTONE: 'create-procedure-milestone',
  CREATE_PROCEDURE_TASK: 'create-procedure-task',
  EDIT_PROCEDURE_TASK: 'edit-procedure-task',
  EDIT_MATERIAL: 'edit-material',
  EDIT_ORGANIZATION: 'edit-organization',
  PROJECT_PLANNING: 'project-planning',
  ASSIGN_PROVIDER: 'assign-provider',
  PROVIDER_TRACKING: 'provider-tracking',
  EDIT_ASSIGNED_PROVIDER: 'edit-assigned-provider',
  CREATE_TODO: 'create-todo',
  CREATE_QUICK_TODO: 'create-quick-todo',
  EDIT_TODO: 'edit-todo',
  CREATE_PROJECT_SIDEBAR: 'create-project-cta',
  CREATE_THREAD_SIDEBAR: 'create-thread-cta',
  CONVERT_TO_GOAL: 'convert-to-goal',
  CONVERT_TO_TODO: 'convert-to-todo',
  NEW_THREAD: 'new-thread',
  CREATE_TODO_FROM_THREAD: 'create-todo-from-thread',
  CREATE_GOAL_FROM_THREAD: 'create-goal-from-thread',
}

export const DATE_TYPE_ICONS = {
  DEADLINE: 'far fa-clock mr-2',
  EXECUTION_DATE: 'far fa-check mr-2',
  NOTIFY_ME: 'far fa-bell mr-2',
  CANCELLED: 'far fa-ban mr-2',
}

export const HTTP_STATUS = {
  UNAUTHORIZED: 401,
}

export const GRAPHQL_EXTENSIONS_CODE = {
  BAD_REQUEST: 'BAD_REQUEST',
  FORBIDDEN: 'FORBIDDEN',
  INTERNAL_ERROR: 'INTERNAL_ERROR',
  NOT_FOUND: 'NOT_FOUND',
  CONFLICT: 'CONFLICT',
} as const

export const AVATAR_COLORS = [
  '#ffecb4',
  '#fffac5',
  '#f0f5c3',
  '#ddedc9',
  '#c9e7c9',
  '#b2dfdb',
  '#b2ebf2',
  '#bcddfb',
  '#c5cae9',
  '#d1c4e8',
  '#b2dfdb',
  '#b3e5fc',
  '#ccd2fa',
  '#e1bee7',
  '#ffcdd2',
  '#dce775',
  '#f19fff',
  '#D4CFFF',
]

export const GRID_TEMPLATE_SORT_FILTER =
  '<div class="ag-cell-label-container grid-custom-header" role="presentation">' +
  '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
  '    <div ref="eText" class="ag-header-cell-text" role="columnheader"></div>' +
  '    <div class="wrapSort">' +
  '      <span ref="eSortAsc" class="grid-header-icon ag-header-asc"></span>' +
  '      <span ref="eSortDesc" class="grid-header-icon ag-header-desc"></span>' +
  '      <span ref="eSortNone" class="grid-header-icon ag-header-asc-desc"></span>' +
  '    </div>' +
  '  </div>' +
  '  <span ref="eMenu" class="grid-header-icon ag-header-filter-menu"></span>' +
  '  <span ref="eFilter" class="grid-header-icon ag-header-filtered"></span>' +
  '</div>'

export const makeMessagesSearchRegex = (query) => `(?![^<>]*>)${query}`

export const CACHE_BLOCK_SIZE = {
  PROJECTS: 60,
  TASKS: 40,
  LATE_TASKS: 40,
  ALL_TASKS: 40,
  ALL_MILESTONES: 60,
  NOTIFICATIONS: 45,
  NOTIFICATIONS_DROPDOWN: 16,
  DASHBOARD_TASKS: 26,
}

export const MAX_BLOCKS_IN_CACHE = {
  PROJECTS: 7,
  TASKS: 9,
  LATE_TASKS: 9,
  ALL_TASKS: 9,
  ALL_MILESTONES: 7,
  NOTIFICATIONS: 9,
  DASHBOARD_TASKS: 9,
}

export const DEFAULT_ROW_HEIGHT = 56

export const RESOURCE_CONTEXT_TYPE = {
  ACCOUNT: 'ACCOUNT',
  PROJECT: 'PROJECT',
  MILESTONE: 'MILESTONE',
  TASK: 'TASK',
  GOAL: 'GOAL',
  COMMENT: 'COMMENT',
  FASHION_COLLECTION_ITEM: 'FASHION_COLLECTION_ITEM',
  ORGANIZATION: 'ORGANIZATION',
} as const

export const ATTACHMENT_TYPE = {
  FILE: 'FILE',
  LINK: 'LINK',
}

export const MILESTONE_DPD_LIMIT = {
  MIN: -3650,
  MAX: 3650,
}

export const RESOURCE_VISIBILITY_PERMISSIONS = {
  [ContextType.Project]: {
    [PROJECT_VISIBILITY().PRIVATE.key]: [RESOURCE_ACCESS().DEFAULT.key],
    [PROJECT_VISIBILITY().PUBLIC.key]: [
      RESOURCE_ACCESS().DEFAULT.key,
      RESOURCE_ACCESS().CUSTOM.key,
    ],
    [PROJECT_VISIBILITY().SHARED.key]: [RESOURCE_ACCESS().CUSTOM.key],
  },
  [ContextType.Milestone]: {
    [PROJECT_VISIBILITY().PRIVATE.key]: [RESOURCE_ACCESS().DEFAULT.key],
    [PROJECT_VISIBILITY().PUBLIC.key]: [
      RESOURCE_ACCESS().DEFAULT.key,
      RESOURCE_ACCESS().CUSTOM.key,
    ],
    [PROJECT_VISIBILITY().SHARED.key]: [RESOURCE_ACCESS().CUSTOM.key],
  },
  [ContextType.Task]: [
    RESOURCE_ACCESS().DEFAULT.key,
    RESOURCE_ACCESS().CUSTOM.key,
  ],
  [ContextType.Goal]: [
    RESOURCE_ACCESS().DEFAULT.key,
    RESOURCE_ACCESS().CUSTOM.key,
  ],
  [ContextType.Comment]: [
    RESOURCE_ACCESS().DEFAULT.key,
    RESOURCE_ACCESS().CUSTOM.key,
  ],
}

export const DEADLINE_CHANGE_REQUEST_STATUS_MAP = (t?: TFunction) => ({
  [DeadlineRequestStatus.Accepted]: {
    label: t('deadlineChangeRequest.accepted'),
    className: 'text-jade-500',
  },
  [DeadlineRequestStatus.Pending]: {
    label: t('deadlineChangeRequest.pending'),
    className: 'text-candlelight-500',
  },
  [DeadlineRequestStatus.Rejected]: {
    label: t('deadlineChangeRequest.rejected'),
    className: 'text-watermelon-500',
  },
  [DeadlineRequestStatus.Custom]: {
    label: t('deadlineChangeRequest.custom'),
    className: 'text-jade-500',
  },
})

export const INVITATION_STATUS = (t?: TFunction) => ({
  PENDING: { key: 'PENDING', value: t?.('invitationStatus.pending') },
  ACCEPTED: { key: 'ACCEPTED', value: t?.('invitationStatus.accepted') },
  EXPIRED: { key: 'EXPIRED', value: t?.('invitationStatus.expired') },
})

export const ORDER_STATUS = (t?: TFunction) =>
  ({
    [OrderStatus.Draft]: {
      key: OrderStatus.Draft,
      value: t?.('materialsManagement.orderStatus.draft'),
      textClass: 'text-oxford-gray-600',
    },
    [OrderStatus.WaitingConfirmation]: {
      key: OrderStatus.WaitingConfirmation,
      value: t?.('materialsManagement.orderStatus.waitingConfirmation'),
      textClass: 'text-candlelight-600',
    },
    [OrderStatus.InProgress]: {
      key: OrderStatus.InProgress,
      value: t?.('materialsManagement.orderStatus.inProgress'),
      textClass: 'text-cornflower-blue-500',
    },
    [OrderStatus.Delivered]: {
      key: OrderStatus.Delivered,
      value: t?.('materialsManagement.orderStatus.delivered'),
      textClass: 'text-jade-600',
    },
    [OrderStatus.Canceled]: {
      key: OrderStatus.Canceled,
      value: t?.('materialsManagement.orderStatus.canceled'),
      textClass: 'text-watermelon-800',
    },
  }) as const

export const CONTRIBUTOR_RIGHTS = (t?: TFunction) => ({
  VIEWER: { value: UserAccess.Viewer, label: t?.('access.viewer') },
  EDITOR: { value: UserAccess.Editor, label: t?.('access.editor') },
  OWNER: { value: 'OWNER', label: t?.('gridHeaderName.owner') },
})

export const TIMELINE_REPORT_FORMATS = {
  MONTHS: {
    key: 'MONTHS',
    colWidth: 150,
    dayWidth: 4.93,
  },
  WEEKS: {
    key: 'WEEKS',
    colWidth: 150,
    dayWidth: 21.2,
  },
}

export const getMonthsList = () => {
  const months = []
  const today = new Date()

  today.setDate(1)

  for (let i = 0; i < 12; i++) {
    today.setMonth(i)

    months.push({
      key: i,
      value: format(today, 'MMM'),
    })
  }

  return months
}

export const getDateChangeTypes = ({ t }: { t?: TFunction }) => ({
  [ChangeType.DeadlineChanged]: {
    key: ChangeType.DeadlineChanged,
    value: t?.('gridHeaderName.deadline'),
  },
  [ChangeType.DynamicPlannerChanged]: {
    key: ChangeType.DynamicPlannerChanged,
    value: t?.('gridHeaderName.remindMe'),
  },
  [ChangeType.ExecutionDateChanged]: {
    key: ChangeType.ExecutionDateChanged,
    value: t?.('gridHeaderName.execDate'),
  },
})

export const getSplitText = ({
  filteredEntities,
  entityCount,
}: {
  filteredEntities: number
  entityCount: number
}) => `${filteredEntities}${entityCount > 0 ? `/${entityCount}` : ''}`

export const getCounterText = ({
  filteredEntities,
  entityCount,
}: {
  filteredEntities: number
  entityCount: number
}) => {
  if (entityCount < 1) return 0
  if (entityCount > 99) return '99+'

  return getSplitText({ filteredEntities, entityCount })
}

export const sortAlphabetically = ({
  data,
  path: pathArray,
}: {
  data: any[]
  path?: string[]
}) => {
  const sortedData = data.sort((a, b) => {
    const firstValue = toLower(
      !isNil(pathArray) && !isEmpty(pathArray) ? path(pathArray)(a) : a,
    )
    const secondValue = toLower(
      !isNil(pathArray) && !isEmpty(pathArray) ? path(pathArray)(b) : b,
    )

    return firstValue?.localeCompare?.(secondValue) ?? 0
  })

  return sortedData
}
