import { RecurringStatus, Status } from '@lib/graphql/__generated__/graphql'
import { TFunction } from '@locales/useTranslations'

export const ENTITY_STATUS: Record<string, Status | string> = {
  NOT_STARTED: Status.New,
  IN_PROGRESS: Status.InProgress,
  DONE: Status.Done,
  CANCELED: Status.Canceled,
}

export const getEntityStatusMeta = (t?: TFunction) => {
  const entityStatusMeta = {
    [ENTITY_STATUS.NOT_STARTED]: {
      key: ENTITY_STATUS.NOT_STARTED,
      value: t?.('status.new'),
      icon: 'text-lg far fa-sticky-note text-oxford-gray-400',
    },
    [ENTITY_STATUS.IN_PROGRESS]: {
      key: ENTITY_STATUS.IN_PROGRESS,
      value: t?.('status.inProgress'),
      icon: 'text-lg far fa-redo-alt fa-flip-horizontal fa-flip-vertical text-cornflower-blue-400',
    },
    [ENTITY_STATUS.DONE]: {
      key: ENTITY_STATUS.DONE,
      value: t?.('status.done'),
      icon: 'text-lg far fa-check text-jade-500',
    },
    [ENTITY_STATUS.CANCELED]: {
      key: ENTITY_STATUS.CANCELED,
      value: t?.('status.canceled'),
      icon: 'text-lg far fa-ban text-watermelon-800',
    },
  }

  return entityStatusMeta
}

export const getRecurringDefinitionStatusMeta = (t?: TFunction) => {
  const recurringDefinitionStatusMeta = {
    [RecurringStatus.InProgress]: {
      key: RecurringStatus.InProgress,
      value: t?.('status.inProgress'),
      icon: 'text-lg far fa-redo-alt fa-flip-horizontal fa-flip-vertical text-cornflower-blue-400',
    },
    [RecurringStatus.Paused]: {
      key: RecurringStatus.Paused,
      value: t?.('status.paused'),
      icon: 'text-lg far fa-pause text-cornflower-blue-400',
    },
    [RecurringStatus.Finished]: {
      key: RecurringStatus.Finished,
      value: t?.('status.finished'),
      icon: 'text-lg far fa-check text-jade-500',
    },
  }

  return recurringDefinitionStatusMeta
}
