import { Notification } from '@lib/types/notification'
import { TFunction } from '@locales/useTranslations'
import { activityNotifications } from './activityNotifications'
import {
  commentNotifications,
  commentReminderNotifications,
  commentReplyNotifications,
} from './commentNotifications'
import { deadlineRequestNotifications } from './deadlineRequestNotifications'
import { exportNotifications } from './exportNotifications'
import { goalNotifications } from './goalNotifications'
import { projectNotifications } from './projectNotifications'
import { taskNotifications } from './taskNotifications'
import { NOTIFICATION_TYPES } from './utils'

const notificationRegistry = {
  [NOTIFICATION_TYPES.PROJECT]: projectNotifications,
  [NOTIFICATION_TYPES.MILESTONE]: activityNotifications,
  [NOTIFICATION_TYPES.GOAL]: goalNotifications,
  [NOTIFICATION_TYPES.TASK]: taskNotifications,
  [NOTIFICATION_TYPES.COMMENT]: commentNotifications,
  [NOTIFICATION_TYPES.COMMENT_REPLY]: commentReplyNotifications,
  [NOTIFICATION_TYPES.COMMENT_REMINDER]: commentReminderNotifications,
  [NOTIFICATION_TYPES.DEADLINE_REQUEST]: deadlineRequestNotifications,
  [NOTIFICATION_TYPES.EXPORT]: exportNotifications,
}

export const renderNotification = ({
  data,
  t,
}: {
  data: Notification
  t: TFunction
}) => {
  try {
    const typeHandlers = notificationRegistry[data.type](t)
    const renderer = typeHandlers?.[data.action]
    return renderer ? renderer(data) : null
  } catch (error) {
    console.error('Error rendering notification:', error)
    return null
  }
}
