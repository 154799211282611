import { Status } from '@lib/graphql/__generated__/graphql'
import { Task } from '@lib/types/task'
import { User } from '@lib/types/user'
import { TFunction } from '@locales/useTranslations'
import { ENTITY_STATUS, TASK_TYPES } from '@utils/constants'
import differenceInDays from 'date-fns/differenceInDays'
import { find, path, propOr } from 'ramda'
import { TimeStamp, getDateFromTimeStamp, getStartOfDay } from './Date'

export const isParent = <T extends { type?: string; [x: string]: any }>(
  task: T,
) => task.type === TASK_TYPES.MAIN

export const isDuplicate = <T extends { type?: string; [x: string]: any }>(
  task: T,
) => task.type === TASK_TYPES.DUPLICATE

export const isSubTask = <T extends { type?: string; [x: string]: any }>(
  task: T,
) => task.type === TASK_TYPES.SUBTASK

export const isActive = <
  T extends { status?: Status | string; [x: string]: any },
>(
  task: T,
) => ![ENTITY_STATUS.CANCELED, ENTITY_STATUS.DONE].includes(task.status)

export const hasSingleAssignee = (task: Task) =>
  propOr([], 'assignees')(task).length === 1

export const isUserAssignee = (user: User, task: Task) =>
  !!find((a) => a?.id === user?.id)(propOr([], 'assignees')(task))

export const isUserOwner = (user: User, task: Task) =>
  user?.id === path(['owner', 'id'])(task)

export const isUserFollower = (user: User, task: Task) =>
  find((f) => f.id === user?.id)(propOr([], 'followers')(task))

export const getTaskDelay = (
  taskDeadline: TimeStamp,
  executionDate: TimeStamp,
) => {
  if (!taskDeadline) return null

  const delay = differenceInDays(
    getStartOfDay(getDateFromTimeStamp(taskDeadline)),
    getStartOfDay(
      executionDate ? getDateFromTimeStamp(executionDate) : new Date(),
    ),
  )

  return delay
}

export const TASK_TYPE_MAP = (t: TFunction) => ({
  [TASK_TYPES.MAIN]: t('expressions.mainTask'),
  [TASK_TYPES.DUPLICATE]: t('expressions.task'),
  [TASK_TYPES.SUBTASK]: t('expressions.subTask'),
})

export const isToDo = (task: Task) => task && !task.parentEntityType
