import { TimeStamp } from '@utils/Date'

const mapTo = (field: string) => (values: string[] | TimeStamp[] | number[]) =>
  values.map((val) => ({
    [field]: val,
  }))

export const mapToStringOrBoolValue = (values: string[] | boolean[]) =>
  values.map((val) => ({
    [typeof val === 'boolean' ? 'boolValue' : 'stringValue']: val,
  }))

export const mapToStringValue = mapTo('stringValue')
export const mapToNumberValue = mapTo('numberValue')
export const mapToTimestampValue = mapTo('timestampValue')
